import { InnerPageLayout } from 'component/InnerPageLayout';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { useUpdateNewsService } from 'services/updateNewsService';

export const UpdateNews = () => {
  const { allNews } = useUpdateNewsService();
  return (
    <InnerPageLayout>
      <ReactMarkdown children={allNews} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} />
    </InnerPageLayout>
  )
}