import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Container } from '@mui/material';
import 'index.scss';
import App from 'App';
import 'i18n';
import bgMain from 'assets/bgMain.png';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <React.Suspense fallback='loading'>
    <div className='background'>
      <Container sx={{
        position: 'relative',
        minHeight: '100vh',
        maxWidth: {
          xl: 'lg',
          lg: 'lg',
          md: 'md',
          sm: '90%',
          xs: '90%'
        },
        backgroundImage: `url(${bgMain})`,
        backgroundRepeat: 'repeat-y',
        pt: 8,
        pb: 12
      }}>
        <div className='bgTop'></div>
        <div className='bgBottom'></div>
        <HashRouter>
          <App />
        </HashRouter>
      </Container>
    </div >
  </React.Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
