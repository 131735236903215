import { Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
// import rehypeSanitize from 'rehype-sanitize';

export const appendGoPage = (content, contentMapping) => {
  const mappingKeys = Object.keys(contentMapping);
  let newContent = content;
  if (mappingKeys.length > 0) {
    mappingKeys.forEach((number) => {
      if (number.includes('(')) {
        number = number.replace('(', '\\(')
        number = number.replace(')', '\\)')
      }
      newContent = newContent.replace(new RegExp(number, 'g'), `<a>${number}</a>`)
    })
  }
  return <ReactMarkdown className='needFSung' children={newContent} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}
    components={{
      a: ({ node, ...props }) => {
        // eslint-disable-next-line no-undef, jsx-a11y/anchor-is-valid, no-script-url
        return <a href='javascript://;' onClick={() => SetCode(contentMapping[node.children[0].value])} >{node.children[0].value}</a>
      },
      blockquote: ({ node, ...props }) => <Typography className='needFSung' sx={{
        whiteSpace: 'initial',
        '& p': {
          margin: '1rem 0 1rem 2rem',
          padding: '0 0 0 5px',
          borderWidth: '0 0 0 3px',
          borderStyle: 'solid',
          borderColor: 'primary.main'
        }
      }} variant='caption' {...props} />
    }}
  />
}