import contentDB from 'db/content.json'
import readAsDB from 'db/contentReadAs.json'
import contentNo from 'db/contentNo.json'
import variantMapping from 'db/variantMapping.json'
import { setting } from 'config/setting';

class Searcher {
  constructor() {
    this.contentDB = contentDB;
    this.readAsDB = readAsDB;
    this.currentBlock = '';
  }

  searchOne(firstTitle, secondTitle) {
    const content = this.contentDB.find(block => block.firstTitle === firstTitle && block.secondTitle === secondTitle).content;
    const result = {
      firstTitle,
      secondTitle,
      content,
      contentMapping: this.#getContentNoMapping(firstTitle, secondTitle, content),
    }
    return result;
  }

  searchContent(keyword) {
    let result = [];
    if (!keyword) {
      return result;
    }

    keyword = this.#normalizeContent(keyword);
    keyword = keyword.trim();

    for (const block of this.contentDB) {
      if (result.length >= setting.maxSearchResultLength) {
        break;
      }

      this.currentBlock = block;

      // 內容可能有多筆符合，需要全部找出來
      const target = new RegExp(keyword, 'g')
      let match;
      while ((match = target.exec(block.cleanContent)) !== null) {
        if (result.length >= setting.maxSearchResultLength) {
          break;
        }
        const searchIndex = match.index;
        const content = this.#getSelectedText(keyword, searchIndex);
        let searchResult = {
          firstTitle: block.firstTitle,
          secondTitle: block.secondTitle,
          contentNo: this.#getContentNumber(searchIndex),
          content,
          contentMapping: this.#getContentNoMapping(block.firstTitle, block.secondTitle, content),
        }

        result.push(searchResult);
      }
    }

    return result;
  }

  searchReadAs(keyword) {
    const result = [];
    if (!keyword) {
      return result;
    }

    keyword = this.#normalizeContent(keyword);
    keyword = keyword.trim();
    keyword = keyword.toLowerCase(); // 讀法不分大小寫
    keyword = `(${keyword})`; // 讀法需要加上括號避免搜尋到其他內容

    for (const block of this.readAsDB) {
      if (result.length >= setting.maxSearchResultLength) {
        break;
      }

      this.currentBlock = block;

      // 內容可能有多筆符合，需要全部找出來
      const target = new RegExp(keyword, 'g')
      let match;
      while ((match = target.exec(block.cleanContent)) !== null) {
        if (result.length >= setting.maxSearchResultLength) {
          break;
        }

        const matchIndex = match.index;

        const content = this.#getSelectedText(keyword, matchIndex);
        let searchResult = {
          firstTitle: block.firstTitle,
          secondTitle: block.secondTitle,
          contentNo: this.#getContentNumber(matchIndex),
          content,
          contentMapping: this.#getContentNoMapping(block.firstTitle, block.secondTitle, content),
        }

        result.push(searchResult);
      }
    }

    return result;
  }

  get #ignoreSearchWords() {
    return ['\r', '\n']
  }

  get #ignorePairs() {
    return [
      { left: '(', right: ')' },
    ]
  }

  get #contentNoFormat() {
    return /【.*?】/g;
  }

  /**
   * 將內容正體化
   * @param {Iterable} input
   * @returns {string} normalized content
   */
  #normalizeContent(input) {
    if (!this.#isIterable(input)) {
      return input;
    }

    // iterate input and normalize content from variantMapping
    let result = '';
    for (const item of input) {
      const normalizedContent = variantMapping[item];
      result += normalizedContent ? normalizedContent : item;
    }

    return result;
  }

  #isIterable(obj) {
    return obj != null && typeof obj[Symbol.iterator] === 'function';
  }

  /**
   * 因為需要正則表達式，indexMap代表的是unit16的index，
   * 但是在計算字的長度也就是"字元"的時候，計算unit32會包含兩個unit16，
   * 為了避免unit16跟unit32的問題，需要透過Array正確計算"字元"，再轉回String計算unit16的長度
   * @param {*} keyword 
   * @param {*} index 
   * @returns 
   */
  #getSelectedText(keyword, index) {
    const content = this.currentBlock.content;
    const previousWordLength = this.#getPreviousWordLength(index);
    const followingWordLength = this.#getFollowingWordLength(index, keyword);

    const startIndex = this.#getMappedIndex(index - previousWordLength);
    const endIndex = this.#getMappedIndex(index + keyword.length + followingWordLength);
    return content.slice(startIndex, endIndex)
  }

  #getMappedIndex(index) {
    if (index < 0) return 0;
    return this.currentBlock.indexMap[index];
  }

  #getPreviousWordLength(searchIndex) {
    const sliceContent = this.currentBlock.cleanContent.slice(0, searchIndex);
    const contentArr = Array.from(sliceContent);

    // get only valid content
    const selectedWords = [];
    let count = 1;
    let ignoreMode = false;
    let findPair = null;
    for (let i = contentArr.length - 1; i >= 0 && count <= setting.resultLength; i--) {
      const fullCharacter = contentArr[i];
      selectedWords.push(fullCharacter);

      // ignore pair word mode
      findPair = this.#ignorePairs.find(pair => pair.right === fullCharacter || pair.left === fullCharacter);

      if (findPair) {
        ignoreMode = true;
      }

      if (ignoreMode) {
        if (findPair && findPair.left === fullCharacter) {
          ignoreMode = false;
          findPair = null;
        }
        continue;
      }

      if (this.#ignoreSearchWords.includes(fullCharacter)) {
        continue;
      }
      count++
    }

    const lastSpecificWordsLength = selectedWords.join('').length;
    return lastSpecificWordsLength;
  }

  #getFollowingWordLength(searchIndex, keyword) {
    const sliceContent = this.currentBlock.cleanContent.slice(searchIndex + keyword.length);
    const contentArr = Array.from(sliceContent);

    // get only valid content
    const selectedWords = [];
    let count = 1;
    let ignoreMode = false;
    let findPair = null;
    for (let i = 0; i < contentArr.length && count <= setting.resultLength; i++) {
      const fullCharacter = contentArr[i];
      selectedWords.push(fullCharacter);

      // ignore pair word mode
      findPair = this.#ignorePairs.find(pair => pair.right === fullCharacter || pair.left === fullCharacter);
      if (findPair) {
        ignoreMode = true;
      }

      if (ignoreMode) {
        if (findPair && findPair.right === fullCharacter) {
          ignoreMode = false;
          findPair = null;
        }
        continue;
      }

      if (this.#ignoreSearchWords.includes(fullCharacter)) {
        continue;
      }
      count++
    }

    const firstSpecificWordsLength = selectedWords.join('').length;
    return firstSpecificWordsLength;
  }

  #getContentNumber(index) {
    const originalContentIndex = this.currentBlock.indexMap[index];
    const regex = /【.*?】/g;
    regex.lastIndex = originalContentIndex;

    const match = regex.exec(this.currentBlock.content);
    if (match === null) {
      return '';
    }

    return this.#parseContentNumberFromSign(match[0]);
  }

  #parseContentNumberFromSign(originalNo) {
    return originalNo.substring(1, originalNo.length - 1);
  }

  #getContentNoMapping(firstTitle, secondTitle, content) {
    const result = {};
    if (!firstTitle || !content) {
      return result;
    }

    // 簡號格式
    const regex = this.#contentNoFormat;
    const matches = content.match(regex);
    if (matches === null) {
      return result;
    }

    matches.forEach(match => {
      const contentNumber = this.#parseContentNumberFromSign(match);
      const key = `${firstTitle}-${secondTitle}-${contentNumber}`;
      result[match] = contentNo[key];
    });

    return result;
  }
}

export { Searcher };
