import { useEffect, useState } from 'react';

export const useScreenSize = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const setWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', setWidth);
    return () => {
      window.removeEventListener('resize', setWidth);
    }
  })

  return { windowWidth }
}