import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { useUpdateNewsService } from 'services/updateNewsService';
import './styles.scss'


export const NewsUpdate = () => {
  const navigate = useNavigate();
  const { latest } = useUpdateNewsService();

  return (
    <>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        <div className='updateNewsTitle' style={{ cursor: 'pointer' }}
          onClick={() => navigate('/news')}>最新消息</div>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 15,
        mx: 'auto',
        fontSize: '.8rem',
        textAlign: 'justify',
        width: {
          md: '70%',
          xs: '100%'
        },
        '& ul': {
          paddingInlineStart: '20px'
        }
      }}>
        <ReactMarkdown children={latest} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} />
        <Button size='small'
          onClick={() => navigate('/news')}
          sx={{
            alignSelf: 'flex-end',
            textTransform: 'none',
            fontWeight: 600,
            ml: 'auto'
          }}
        >全部...</Button>
      </Box>
    </>
  )
}