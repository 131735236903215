import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Searcher } from 'model/searcher';
import { useResultsService } from 'services/resultsService';

const btnProps = {
  size: 'small',
  variant: 'contained',
  sx: {
    my: 3,
    mx: {
      sm: 3,
      xs: 1
    },
    fontSize: '.8rem',
    fontWeight: '400',
    letterSpacing: 2,
    borderRadius: '8px',
    color: 'secondary.dark',
    px: {
      sm: 3,
      xs: 2
    },
    py: 0,
    '&:hover': {
      backgroundColor: 'primary.main'
    }
  },
  disableElevation: true
}

export const SearchArea = ({ searchCharacter, inputCharacter, openBujian, setOpenBujian }) => {
  const { setResults } = useResultsService();
  const navigate = useNavigate();
  const searchAll = (keyword) => {
    const searcher = new Searcher();
    const results = searcher.searchContent(keyword);
    if (results.length === 0) alert('查無內容');
    // console.log(results);
    setResults(results);
    navigate('/results');
  }
  const searchReadAs = (keyword) => {
    const searcher = new Searcher();
    const results = searcher.searchReadAs(keyword);
    if (results.length === 0) alert('查無內容');
    // console.log(results);
    setResults(results);
    navigate('/results');
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      mt: 3
    }}>
      <TextField
        value={searchCharacter}
        onChange={(e) => inputCharacter(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') searchAll(e.target.value)
        }}
        InputProps={{
          className: 'needFSung',
          startAdornment: (
            <InputAdornment position='start' sx={{ color: 'primary.main' }}>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <Button
                size='small'
                variant='contained'
                disableElevation
                startIcon={<AddCircleIcon />}
                sx={{
                  backgroundColor: 'primary.dark',
                  borderRadius: '8px',
                  px: 1,
                  py: 0,
                  fontWeight: '400',
                  letterSpacing: 2,
                  color: 'secondary.dark',
                  fontSize: '.8rem'
                }}
                onClick={() => setOpenBujian(!openBujian)}
              >插入難字</Button>
            </InputAdornment>
          )
        }}
        sx={{
          width: {
            md: '70%',
            xs: '100%'
          },
          backgroundColor: 'secondary.dark',
          borderRadius: '12px',
          '& .MuiInputBase-root': {
            height: '35px',
            padding: '0 10px',
          },
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }} />
      <Box>
        <Button {...btnProps} onClick={() => searchAll(searchCharacter)}>釋文檢索</Button>
        <Button {...btnProps} onClick={() => searchReadAs(searchCharacter)}>讀法檢索</Button>
      </Box>
    </Box>
  )
}