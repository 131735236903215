import { useEffect } from 'react';
import { Card, CardContent, CardHeader, CardActions, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InnerPageLayout } from 'component/InnerPageLayout';
import { useResultsService } from 'services/resultsService';
import { useNavigate } from 'react-router-dom';
import { appendGoPage } from 'util/appendGoPage';

export const ReadMore = () => {
  const navigate = useNavigate();
  const { readMoreResult } = useResultsService();
  const { firstTitle, secondTitle, content, contentMapping } = readMoreResult;

  useEffect(() => {
    if (readMoreResult.content === undefined) navigate('/');
  }, [readMoreResult.content])

  return (
    <InnerPageLayout>
      {readMoreResult && (
        <Card variant=''
          sx={{
            overflow: 'unset',
            mt: '1%',
            backgroundColor: 'transparent'
          }}
        >
        <CardActions sx={{
            justifyContent: 'end'
          }}>
            <Button size='small'
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate(-1)
              }}
            >回前一頁</Button>
          </CardActions>
          <CardHeader
            title={`${firstTitle} ${secondTitle}`}
            titleTypographyProps={{ variant: 'h6', component: 'h2', className: 'needFSung' }}
            sx={{
              p: 1, '& .MuiCardHeader-action': {
                alignSelf: 'initial',
                m: 0
              }
            }}
          >
          </CardHeader>
          <CardContent sx={{
            '& *': {
              my: 1
            },
            '& p': {
              lineHeight: 2,
              textAlign: 'justify',
            }
          }}>
            {readMoreResult.content && appendGoPage(content, contentMapping)}
          </CardContent>
          <CardActions sx={{
            justifyContent: 'end'
          }}>
            <Button size='small'
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate(-1)
              }}
            >回前一頁</Button>
          </CardActions>
        </Card>
      )}
    </InnerPageLayout>
  )
}