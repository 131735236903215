import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { useTranslation } from 'react-i18next';
import { Typography, Container, TableCell, Table, TableHead, TableBody, TableRow } from '@mui/material';
import { InnerPageLayout } from 'component/InnerPageLayout';;
// import howto from 'contents/howTo.md';;
// import intro from 'contents/intro.md';

export const Information = () => {
  const location = useLocation();
  const [text, setText] = useState('');
  const { t } = useTranslation('contents')
  // console.log(howto)

  const switchPageContent = () => {
    switch (location.pathname) {
      case '/howto':
        fetch(`./contents/howTo.md`).then(res => res.text()).then(text => setText(text))
        // fetch(`${t('howTo')}`).then(res => res.text()).then(text => setText(text))
        return (
          <Container>
            <ReactMarkdown children={text} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]}
              components={{
                // eslint-disable-next-line jsx-a11y/alt-text
                img: ({ node, ...props }) => <img {...props} style={{ width: '300px', height: '300px', objectFit: 'contain' }} onError={(e) => e.target.style.display = 'none'} />,
                p: ({ node, ...props }) => <Typography variant='body2' component={node.tagName} sx={{ fontSize: '1rem' }} className='needFSung' {...props} />,
                td: ({ node, ...props }) => <TableCell className='needFSung' sx={{ fontSize: { md: '1.15rem', xs: '1rem' } }} {...props} />,
                table: ({ node, ...props }) => <Table className='needFSung' {...props} />,
                thead: ({ node, ...props }) => <TableHead className='needFSung' {...props} />,
                tbody: ({ node, ...props }) => <TableBody className='needFSung' {...props} />,
                tr: ({ node, ...props }) => <TableRow className='needFSung' {...props} />,
                ul: ({ node, ...props }) => <ul className='needFSung' {...props} />
              }}
            />
          </Container>
        )
      case '/intro':
      default:
        fetch('./contents/intro.md').then(res => res.text()).then(text => setText(text))
        return (
          <Container>
            <ReactMarkdown children={text} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]}
              components={{
                p: ({ node, ...props }) => <Typography className='needFSung' variant='body2' component={node.tagName} sx={{ fontSize: '1rem' }}  {...props} />,
                td: ({ node, ...props }) => <TableCell className='needFSung' {...props} />,
                table: ({ node, ...props }) => <Table className='needFSung' {...props} />,
                thead: ({ node, ...props }) => <TableHead className='needFSung'  {...props} />,
                tbody: ({ node, ...props }) => <TableBody className='needFSung'  {...props} />,
                tr: ({ node, ...props }) => <TableRow className='needFSung'  {...props} />,
                ul: ({ node, ...props }) => <ul className='needFSung' {...props} />
              }}
            />
          </Container>
        )
    }
  }

  return (
    <InnerPageLayout>
      {
        switchPageContent()
      }
    </InnerPageLayout>
  )
}