import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import logo_h from 'assets/logo_h.png';
import logo_v from 'assets/logo_v.png';

export const Title = ({ direction, handleClick }) => {
  const location = useLocation();

  switch (direction) {
    case 'vertical':
      return (
        <Box sx={{
          marginTop: '-120px',
          cursor: `${handleClick ? 'pointer' : 'initial'}`,
          '& img': {
            marginRight: '20%',
            height: {
              lg: '55vh',
              md: '40vh',
              sm: '280px',
              xs: '25vh'
            }
          }
        }} onClick={handleClick}>
          <img src={logo_v} alt="戰國竹簡全文資料庫" />
        </Box>
      )
    case 'horizontal':
    default:
      return (
        <Box sx={{
          textAlign: 'center',
          cursor: `${handleClick ? 'pointer' : 'initial'}`,
          '& img': {
            width: {
              lg: '40%',
              md: '40%',
              sm: '70%',
              xs: location.pathname === '/' ? '100%' : '75%'
            }
          }
        }} onClick={handleClick}>
          <img src={logo_h} alt="戰國竹簡全文資料庫" />
        </Box>
      )
  }
}
