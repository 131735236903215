import { useState, useEffect, useCallback } from 'react';
import { Title } from 'component/Title';
import { BujianJiansuo } from 'component/BujianJiansuo';
import { NewsUpdate } from 'component/NewsUpdate';
import { SearchArea } from 'component/SearchArea';
import { useResultsService } from 'services/resultsService';
import slipDecoration from 'assets/slipDecoration.png';
import divider from 'assets/divider.png';

export const Main = () => {
  const [searchCharacter, setSearchCharacter] = useState('');
  const [openBujian, setOpenBujian] = useState(false);
  const { results, setResults } = useResultsService();

  const chooseCharacter = useCallback((newChar) => {
    setSearchCharacter(pre => {
      return pre + newChar
    })
  }, []);
  const inputCharacter = useCallback((value) => {
    setSearchCharacter(value)
  }, []);

  useEffect(() => {
    if (results.length > 0) setResults([]);
  }, [])

  return (
    <>
      <Title direction='horizontal' handleClick={null} />
      <SearchArea
        searchCharacter={searchCharacter}
        inputCharacter={inputCharacter}
        openBujian={openBujian}
        setOpenBujian={setOpenBujian}
      />
      {openBujian && <BujianJiansuo chooseCharacter={chooseCharacter} />}
      <img src={divider} alt='' style={{
        height: '100%',
        width: '100%',
        display: 'block',
        marginBottom: '2%'
      }} />
      <NewsUpdate />
      <div className='slipDecoration'>
        <img src={slipDecoration} alt='竹簡' />
      </div>
    </>
  )
}