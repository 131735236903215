import { useState, useEffect, createContext, useContext } from 'react';
// import news from 'contents/news.md';

const updateNewsContext = createContext();

export const UpdateNewsService = ({ children }) => {
  const [latest, setLatest] = useState();
  const [allNews, setAllNews] = useState();
  useEffect(() => {
    fetch('./contents/news.md').then(res => res.text())
      .then(text => {
        const latestNews = text.split('-').slice(0, 4);
        setLatest(latestNews.join('-'));
        setAllNews(text);
      })
  }, [])

  return <updateNewsContext.Provider value={{ latest, allNews }}>
    {children}
  </updateNewsContext.Provider>
}

export const useUpdateNewsService = () => useContext(updateNewsContext);