import { useScreenSize } from 'util/useScreenSize';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Title } from 'component/Title';

export const InnerPageLayout = ({ children }) => {
  const { windowWidth } = useScreenSize();
  const navigate = useNavigate();
  return (<Box sx={{
    display: 'flex',
    flexDirection: {
      sm: 'row',
      xs: 'column'
    }
  }}>
    <Title direction={windowWidth > 600 ? 'vertical' : 'horizontal'} handleClick={() => navigate('/')} />
    {children}
  </Box>)
}