/* eslint-disable no-undef */
import { useEffect, useState, useRef } from 'react'
import { Button, Stack, Table, TableBody, TableRow, TableContainer, Paper, Typography, TextField, Box } from '@mui/material';
import './styles.scss'

const kt = [
  '筆,畫:一丨丶,㇒丿㇀,㇈亅乚,㇄㇁㇂,𠄌𠃊𠃋,𠃎𠃍󰎀,𡿨乛㇇,㇏乀⺄,𠃌㇆󰋌,㇉𠃑㇊,㇣㐅乂,󰊅󰊸󰕐,𰀪⺀巜',
  '字,頭:艹⺾艹䒑𥫗龴,⺈𠆢𠂉󰓶亠𠚤,丷⺌𭕄爫爫󰒈,𠂆厂󰐡广疒𤕫,厃产󰒖屵严󺪊,尸𠃜󰓏耂𠫓󶅠,罒㓁罓龷𠀎业,覀⺜龶⺻󰋐󰉬,冖⺳宀龸󰒑󰓔,𤇾𫇦󰋚󰓐󰋞󰋟,󰊟󰒾癶󰒛龹𡗗,⻗󰋍𦥯𰃮龻𰁜,𠂒虍󶃛󰒭𦍌󰓗',
  '包,圍:冂⺆,󰒂𠘨,凵󰍓,匚𠥓,匸󰒦,勹𦉶,󰊥󰊶,󰓴󶏔,󰑖󰑹,𢦏󰑷,󰏹㦰,󰐂𦝠,囗󰑃',
  '計,數:甲乙丙丁,戊己庚辛,壬癸千万,子丑寅卯,辰巳午未,申酉戌亥,一二三百,四五六七,八九十廿,卄卅卌𠦃,分寸尺丈,上丄下丅,大中小𡭔',
  '自,然:日月,𱼀夕,風气,雨雲,金釒,木钅,水氵,氺󰐻,火灬,土石,冫仌,天倝,光',
  '地,理:山㐀,丘业,川𫶧,巛卝,穴谷,𣶒󰑉,岡屯,州市,皋𠂤,阜⻖,𨸏阝,邑⻏',
  '動,物:鼠牛牜虎,兔㲋龍巴,虫馬羊⺶,⺸𦍋𦫳夒,犬犮犭豸,豕𧰨𢊁廌,魚鳥隹鹿,嘼黽黾龜,彐彑㣇貝,角羽毛巤,爪󰒬釆禸,习卂飞飛,隺禺󰊎象',
  '植,物:艸屮屮,木朩林,竹禾𥝌,米𠂭麥,麻黍桼,豆瓜𤓰,朿𣎵󰏶,朮𣏟韭,支本末,丰󰐸丯,華𢎘,卉芔丵,𠧪𠂹𣐺',
  '人,體:首頁𦣻𡿺囟,髟丱卝㐱彡,冉冄而口亼,舌牙㸦齒𧮫,目耳眉𦣞𦣝,鼻自面靣身,心忄⺗血骨,肉󰿰⺼󰐧皮,手扌龵𠂇䶹,𰀂𰀄又⺕𫜹,𦥑廾󰊓𠬞𠬜,足𧾷疋𤴔止,龰丮𠃨󰉾',
  '食:食,飠,𩙿,饣,丣,鹵,鬯,甘,𤮺,香,皀',
  '衣:衣𧘇,衤冃,巾巿,糸纟,糹黹,革𮧓,韋韦,玉⺩,圭,文布',
  '住:門,戶,囱,囪,几,舍,囧,瓦,井,爿,丬,田,里',
  '行:行走,辵辶,⻌⻍,廴夂,𫝀夊,𡕒㐄,彳亍,舟󰐪,車去,立至,入𣥂,冘夭,隶夋',
  '樂:聿,𦘒,肀,冊,册,𠕁,典,史,壴,鼓,龠,殸,音',
  '祭:卜,⺊,爻,兆,示,礻,𥘅,鬼,甶,亯,󰔃,用,夬',
  '器,物:皿酉,缶𦈢,网𦉰,冈𠔿,𦉫𦉪,匕𠁁,斗臼,鬲鼎,丌󰊓,耒𦓤,𠦒甾',
  '軍,事:刀刂,力刄,刃刅,斤弋,弓󰓹,干矢,戈戉,矛盾,殳𠘧,卩㔾,攴攵,㫃𭤨,爭鬥',
  '人,稱:人亻,󰉼𠂊,乑𫡑,厶公,我爾,父母,兄弟,𠦑子,儿女,士夫,王君,臣妾,氏民',
  '命:生,老,歹,歺,𣦵,亡,亾,𠤎,尢,尣,辛,䇂,卑',
  '色:青,靑,白,丹,朱,赤,烏,黑,玄,黃,灰,色',
  '動,作:言讠,訁曰,吅云,从采,𡈼工,見艮,欠夨,襾󰒊,𡳾𠂈,疌睘,非,勿毋,弗不',
  '雜,類:長镸长高𠦝肅,丆乁幺乡㇋𠄎,丂𠀁亏亐󰑲〢,𠔼冋冎㒳㡀㒼,兀无旡兂圥󰩮,方𠮠片𰀁𢆉𦍍,㠯毌齊齐段叚,比𠃏丩𠂎乇也,舛夗开幵并幷,𠂔𪩲巨亞亜戼,𠀃且󰑸󰑈󰐔󰑱,東西南北左右,𢑚𠃬𢏚㢴󰔲叀'
];

const inputBtnProps = {
  variant: 'contained',
  disableElevation: true,
  size: 'small',
  sx: {
    backgroundColor: '#d2b57c',
    border: '1.5px solid #876e4e',
    color: 'secondary.main',
    height: '2em',
    width: '2em',
    minWidth: '2em',
    // minWidth: {
    //   md: '64px',
    //   sm: '30px',
    //   xs: '40px'
    // },
    // maxWidth: {
    //   sm: '30px'
    // },
    // px: {
    //   sm: 3,
    //   xs: 0
    // },
    '&:hover': {
      backgroundColor: '#d2b57c',
    }
  }
}
export const BujianJiansuo = ({ chooseCharacter }) => {
  const bujianOutput = useRef(null);
  const [showCharaterCount, setShowCharaterCount] = useState(false);

  useEffect(() => {
    let clickTimer;
    bujianOutput.current.addEventListener('click', (e) => {
      if (e.target.nodeName === 'A') {
        e.preventDefault();
        if (e.detail === 1) {
          clickTimer = setTimeout(() => {
            chooseCharacter(e.target.textContent);
          }, 300)
        }
        if (e.detail > 1) {
          clearTimeout(clickTimer)
        }
      }
    })
  }, [])
  // 鍵盤開關改變
  // let isPadVisible = true;
  // const OnPad = function () {
  //   isPadVisible = !isPadVisible;
  //   // SetCookie('keypad', isPadVisible ? '1' : '0');
  //   document.getElementById('padbtn').innerHTML = isPadVisible ? '▲' : '▼';
  //   document.getElementById('keypad').style.display = isPadVisible ? 'block' : 'none';
  //   document.getElementById('input').focus();
  // };

  // 清除檢索
  const ClearFind = function () {
    const e = document.getElementById('input');
    e.value = '';
    e.focus();
    FindMatch(0, false);
  };

  // 倒退清除
  const Backspace = function () {
    const e = document.getElementById('input');
    const n = GetPos(e);
    if (n > 0) {
      const s = e.value;
      const c = s.charCodeAt(n - 2);
      const m = ((c >= 0xD800) && (c <= 0xDBFF)) ? n - 2 : n - 1;
      e.value = s.slice(0, m) + s.slice(n);
      SetPos(e, m);
      FindMatch(0, true);
    }
  };

  // 解構漢字
  const Decompose = function () {
    const e = document.getElementById('input');
    const n = GetPos(e);
    if (n > 0) {
      const s = e.value;
      let m = n - 1;
      let w = s.charAt(m);
      if (m > 0) {
        const c = s.charCodeAt(m - 1);
        if ((c >= 0xD800) && (c <= 0xDBFF))
          w = s.charAt(--m) + w;
      }

      const d = true;
      // const d = document.getElementById('subdivide').checked;
      const t = Exhaust(w, d, 0);
      if (t.length) {
        e.value = s.slice(0, m) + t + s.slice(n);
        SetPos(e, m + t.length);
        FindMatch(0, true);
      }
    }
  };

  useEffect(() => {
    // document.getElementById('version').innerHTML = GetVersion();
    // document.getElementById('variant').checked = true;
    // document.getElementById('subdivide').checked = true;
    // document.getElementById('ucodeonly').checked = GetCookie('ucodeonly', '0') === '1';
    // document.getElementById('onthefly').checked = GetCookie('onthefly', '0') === '1';
    // document.getElementById('largefont').checked = GetCookie('largefont', '0') === '1';
    // document.getElementById('copymode').checked = true;
    document.getElementById('input').addEventListener('compositionstart', function () { ime = true; });
    document.getElementById('input').addEventListener('compositionend', function () { setTimeout(function () { ime = false; FindMatch(0, true); }, 1); });

    // OnFnt();
    let s = '';
    for (let i = 0; i < kt.length; i++) {
      s += "<th class='Cathdr'>";
      for (let j = 0; j < kt[i].length; j++) {
        let w = kt[i].charAt(j);
        const c = w.charCodeAt(0);
        if ((c >= 0xD800) && (c <= 0xDBFF)) w += kt[i].charAt(++j);
        if (w === ':')
          break;
        else if (w === ',')
          s += '<br>';
        else
          s += w;
      }
      s += '</th>';
    }
    document.getElementById('cathdr').innerHTML = s;
    let t = '';
    for (let i = 0; i < kt.length; i++) {
      t += "<td class='Catbdy'>";
      for (let j = kt[i].indexOf(':') + 1; j < kt[i].length; j++) {
        let w = kt[i].charAt(j);
        const c = w.charCodeAt(0);
        if ((c >= 0xD800) && (c <= 0xDBFF)) w += kt[i].charAt(++j);
        if (w === ',')
          t += '<br>';
        else
          t += "<button class='Key' onclick='Key(\"" + w + "\")' oncontextmenu='SetClipBoard(\"" + w + "\");return false;'>" + w + "</button>";
      }
      t += '</td>';
    }
    document.getElementById('catbtn').innerHTML = t;
    // isPadVisible = GetCookie('keypad', '1') === '0';
    // OnPad();
  }, [])
  return (
    <>
      <div className='Header'>
        {/* <hr size='10' color='#C0C0C0' /> */}
        {/* <span className='Line'>
          <Typography variant='body1' className='Title'>部件檢索 - 198806字</Typography>
        </span> */}
        {/* <hr color='#C0C0C0' /> */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 8,
            flexDirection: {
              sm: 'row',
              xs: 'column'
            },
            alignItems: {
              sm: null,
              xs: 'center'
            }
          }}
        >
          {/* {/* <Typography variant='body1' sx={{
            display: 'inline-block'
          }} className='LabelB'>部件：</Typography> */}
          <TextField className='Editor' id='input'
            onInput={() => {
              setShowCharaterCount(false);
              FindMatch(0, true);
            }}
            onKeyDown={(event) => { if (event.keyCode === 0x0D) setShowCharaterCount(true); OnKey(event.keyCode) }}
            size='small'
            placeholder='輸入部件'
            InputProps={{
              sx: {
                borderRadius: '4px',
                height: '30px',
              }
            }}
            sx={{
              backgroundColor: 'secondary.dark',
              borderRadius: '4px',
              height: '30px',
              width: '200px',
              '& .MuiInputLabel-root': {
                fontSize: '.7rem',
                top: '-4px'
              },
              '& fieldset.MuiOutlinedInput-notchedOutline': {
                borderWidth: '1.5px',
                borderColor: 'secondary.main'
              }
            }}
          />
          <Stack direction='row' spacing={1} sx={{
            display: 'inline-flex',
            flexWrap: {
              sm: 'nowrap',
              xs: 'wrap'
            },
            ml: 1,
            mt: {
              sm: 0,
              xs: 1
            }
          }}>
            <Button {...inputBtnProps} title='清除' onClick={() => { setShowCharaterCount(false); ClearFind() }}><b>X</b></Button>
            <Button {...inputBtnProps} title='倒退' onClick={Backspace}>⌫</Button>
            <Button {...inputBtnProps} title='解構' onClick={Decompose}><b>\</b></Button>
            <Button {...inputBtnProps} title='獨體' onClick={() => {
              ClearFind(); Key('#')
            }}><b>#</b></Button>
            <Button {...inputBtnProps} title='查詢' onClick={() => {
              setShowCharaterCount(true);
              FindMatch(0, false);
            }}>🔍</Button>
            <Box>⌘<span id="FnKey"></span></Box>
          </Stack>
        </Box>
        {/* <span className='Line Options'><span className='LabelR'>選項</span>：
                <input type='checkbox' id='variant'
                    onClick='OnVar()'>包容異體</input> <input type='checkbox' id='subdivide' onClick='OnSdv()'>無理拆分</input> <input
                        type='checkbox' id='ucodeonly' onClick='OnUco()'>限標準字</input> <input type='checkbox' id='onthefly'
                            onClick='OnFly()'>即時查詢</input> <input type='checkbox' id='largefont' onClick='OnFnt()'>較大字形</input> <input
                                type='checkbox' id='copymode' onClick='OnCpy()'>複製模式</input></span> */}
        <Box className='Line Options'
          sx={{
            opacity: `${showCharaterCount ? 1 : 0}`,
            mt: 2,
            mb: 1,
            mx: {
              lg: 8,
              md: 5,
              xs: 0
            },
            display: 'flex',
          }}
        >
          <Typography variant='body1'>字數：</Typography>
          <Typography variant='body1' className='Counter' id='counter'></Typography>
        </Box>
        {/* <hr color='#C0C08C0' /> */}
      </div>
      <Box className='Keyboard'
        sx={{
          mx: {
            lg: 8,
            md: 5,
            xs: 0
          }
        }}
      >
        {/* <span className='Line'><Button variant='ouilne' className='Color' id='padbtn' title='鍵盤收/展' onClick={OnPad}>▲</Button>⌨</span> */}
        <span className='Line' id='keypad'>
          <TableContainer component={Paper}>
            <Table className='Keypad'>
              <TableBody>
                <TableRow id='cathdr'
                  sx={{
                    backgroundColor: '#816347',
                    color: 'secondary.light',
                  }}></TableRow>
                <TableRow id='catbtn'
                  sx={{
                    backgroundColor: 'secondary.dark',
                    borderColor: '#d2b275',
                    borderStyle: 'solid',
                    borderWidth: '1px'
                  }}
                ></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </span>
      </Box>
      <div className='Content'>
        {/* <span className='Line Legend'><span className='LabelL'>⨠</span> <button className='Color BMP' onClick='Key('@')'>　</button>
                    基本　<button className='Color ExA' onClick='Key('A')'>　</button> A區　<button className='Color ExB'
                        onClick='Key('B')'>　</button> B區　<button className='Color ExC' onClick='Key('C')'>　</button> C區　<button
                            className='Color ExD' onClick='Key('D')'>　</button> D區　<button className='Color ExE' onClick='Key('E')'>　</button>
                    E區　<button className='Color ExF' onClick='Key('F')'>　</button> F區　<button className='Color ExG'
                        onClick='Key('G')'>　</button> G區　<button className='Color ExH' onClick='Key('H')'>　</button> H區　<button
                            className='Color PAI' onClick='Key('P')'>　</button> PAI　<button className='Color CMP' onClick='Key('X')'>　</button>
                    相容　<button className='Color SUP' onClick='Key('Y')'>　</button> 補充　<button className='Color OTH'
                        onClick='Key('Z')'>　</button> 其他　</span> */}
        <Box className='Line Output' id='output' ref={bujianOutput}
          sx={{
            mx: {
              lg: 8,
              md: 5,
              xs: 0
            },
            mt: 2,
            display: 'flex',
            flexWrap: 'wrap',
            // justifyContent: 'space-between'
          }}
        ></Box>
        <br />
      </div>
      {/* <span style={{ display: 'block', textAlign: 'right' }}><small>核心版本：<span id='version'></span></small></span>
      <span style={{ display: 'block', textAlign: 'right' }}><small>發布網址：<a
        href='http://fgwang.blogspot.tw/2015/12/blog-post_30.html' target='_blank' rel='noreferrer' >部件檢索</a> (WFG製作)</small></span> */}
    </>
  )
}

// export const BujianJiansuo = () => {
//     const [html, setHtml] = useState('')
//     const page = useRef();

//     // 獲取 htm 檔案並改成 unicode 編碼
//     useEffect(() => {
//         (async() => {
//             const html = await fetch('./bujian.htm').then(res => {
//                 return res.arrayBuffer();
//             }).then((buffer) => {
//                 const decoder = new TextDecoder('unicode');
//                 const html = decoder.decode(buffer);
//                 return html;
//             });
//             setHtml(html);
//         })()
//     }, [])

//     // 尋找 <script> 並額外 append 至 document 上
//     useEffect(() => {
//         const scripts = page.current.querySelectorAll('script');
//         console.log('script', scripts)
//         console.log('document', document)
//         scripts.forEach(script => {
//             script.remove();
//         })
//     }, [page.current])

//     return <div dangerouslySetInnerHTML={{__html: html}} ref={page}/>
// }
