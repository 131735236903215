import { Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline, Container } from '@mui/material/';
import 'App.scss';
import { Main } from 'page/Main';
import { Information } from 'page/Information';
import { Navigator } from 'component/Navigator'
import { Result } from 'page/Result';
import { ReadMore } from 'page/ReadMore';
import { UpdateNews } from 'page/UpdateNews';
import { ResultsService } from 'services/resultsService';
import { Footer } from 'component/Footer';
import { UpdateNewsService } from 'services/updateNewsService';

let theme = createTheme({
  palette: {
    primary: {
      main: '#7d2e2b',
      dark: '#5c4630'
    },
    secondary: {
      main: '#876e4e',
      dark: '#e3d3b3',
      light: '#ecdec0'
    }
  },
  typography: {
    fontFamily: `'Noto Sans TC', sans-serif`,
  }
});
theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navigator />
      <Container sx={{
        maxWidth: {
          lg: 'lg',
          md: 'md',
          sm: '95%'
        }
      }}>
        <UpdateNewsService>
          <ResultsService>
            <Routes>
              <Route path='/' element={<Main />}></Route>
              <Route path='intro' element={<Information />}></Route>
              <Route path='howto' element={<Information />}></Route>
              <Route path='results' element={<Result />}></Route>
              <Route path='readmore' element={<ReadMore />}></Route>
              <Route path='news' element={<UpdateNews />}></Route>
            </Routes>
          </ResultsService>
        </UpdateNewsService>
        <Footer />
      </Container>
    </ThemeProvider>
  )
}

export default App;
