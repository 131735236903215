import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from 'i18next-resources-to-backend';

i18n
  .use(initReactI18next)
  .use(resourcesToBackend((language, namespace) => import(`../public/locales/${language}/${namespace}.json`)))
  .init({
    fallbackLng: "zh-Hant-TW",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;