import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardActions, Button, CardHeader, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { InnerPageLayout } from 'component/InnerPageLayout';
import { useResultsService } from 'services/resultsService';
import { Searcher } from 'model/searcher';
import { appendGoPage } from 'util/appendGoPage';
import divider from 'assets/divider.png';

export const Result = () => {
  const navigate = useNavigate();
  const { results, setReadMoreResult } = useResultsService();

  const readmore = (firstTitle, secondTitle) => {
    const searcher = new Searcher();
    const readMoreResult = searcher.searchOne(firstTitle, secondTitle);
    // console.log('readMoreResult', readMoreResult)
    setReadMoreResult(readMoreResult);
    navigate('/readmore');
  }

  useEffect(() => {
    if (results.length === 0) navigate('/');
  }, [results])

  return (
    <InnerPageLayout>
      <Box>
        {
          results.map(result => {
            return (
              <Card
                key={result.content}
                variant=''
                sx={{
                  position: 'relative',
                  overflow: 'unset',
                  mt: '1%',
                  ml: '3%',
                  backgroundColor: 'transparent'
                }}
              >
                <CardHeader
                  disableTypography
                  title={<Typography variant='h6' component='h2' className='needFSung'>{result.firstTitle} {result.secondTitle}<span style={{ marginLeft: '10%' }}>{result.contentNo}</span></Typography>}
                  sx={{
                    fontWeight: '700!important',
                    p: 1,
                  }}
                />
                <CardContent sx={{ p: 1, '& p': { my: 1 } }}>
                  {appendGoPage(result.content, result.contentMapping)}
                </CardContent>
                <CardActions>
                  <Button size='small'
                    startIcon={<AddIcon />}
                    onClick={() => { readmore(result.firstTitle, result.secondTitle) }}
                    sx={{
                      fontWeight: 600
                    }}
                  >閱讀更多</Button>
                </CardActions>
                <img src={divider} alt='' style={{
                  height: '100%',
                  width: '100%',
                  display: 'block'
                }} />
              </Card>
            )
          })
        }
      </Box>
    </InnerPageLayout >
  )
}