const controller = {
  ignorePatterns: [ // 忽略搜尋的pattern
     /【.*?】/gu, // 簡號
    /⟨.*?⟩/gu, // 訂正
    /（.*?）/gu, // 註解
    //⟦.*?⟧/gu, // 補字
    /> .*[。：「」）】]/gu, // 引文
    /[．，、。：；？?！「」『』＝⸻᠆■▍└|－□〼｟｠〈〉《》]+/gu, // 單個標點符號
  ],
  readAsPatterns: [ // 讀法的pattern
    /\(.*?\)/gu,
  ],
  originalMD: '釋文', // 原始資料的 markdown 檔案名稱
  introductionMD: '簡介', // 簡介的 markdown 檔案名稱
  newsMD: '最新消息', // 最新消息的 JSON 檔案名稱
  howToMD: '使用說明', // 使用說明的 markdown 檔案名稱
  contentNoExcel: '簡號頁碼對應表', // 簡號對照表的 excel 檔案名稱
  variantCsv: '釋文異體對應表', // 異體字對照表的 csv 檔案名稱
  resultLength: 5, // 搜尋結果的長度
  maxSearchResultLength: 100, // 最大搜尋長度
  languageOption: [ //語言下拉選單選項
    {
      display: '中文',
      languageCode: 'zh-Hant-TW',
    },
    {
      display: 'English',
      languageCode: 'en',
    }
  ],
  email: 'paiyuyin@script.tw'
}

export { controller as setting }