import { useState, useRef, useEffect } from 'react';
import { setting } from 'config/setting';
import { Select, MenuItem, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import logo_b from 'assets/logo_b.png';

export const Footer = () => {
  const [language, setLanguage] = useState('zh-Hant-TW');
  const [selectMenuAnchor, setSelecMenuAnchor] = useState(null);
  const selecEl = useRef();
  const { t, i18n } = useTranslation();
  const selectLanguage = (e) => {
    const { target: { value } } = e;
    setLanguage(value);
    i18n.changeLanguage(value);
  }
  useEffect(() => {
    setSelecMenuAnchor(selecEl.current);
  }, [])
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      right: 0,
      left: 0,
      bottom: '25px',
      px: 2
    }}>
      <Select
        ref={selecEl}
        value={language}
        onChange={selectLanguage}
        size='small'
        sx={{
          height: '35px',
          borderRadius: '4px',
          borderColor: '#816347',
          fontSize: '.8rem',
          '& fieldset': {
            borderColor: '#816347',
          }
        }}
        MenuProps={{
          anchorEl: selectMenuAnchor,
          anchorOrigin: {
            vertical: 'top',
          },
          transformOrigin: {
            vertical: 'bottom',
          },
          disableAutoFocusItem: true,
          // sx: {
          //   top: '-47px',
          //   left: '0px'
          // },
          PaperProps: {
            elevation: 0,
            sx: {
              fontSize: '.8rem',
              borderRadius: '2px',
              backgroundColor: '#816347',
              color: 'secondary.light',
              padding: 0,
              '& .MuiList-root': {
                padding: 0,
                '& li': {
                  fontSize: '.8rem'
                }
              },
              '& .MuiMenuItem-root.Mui-selected': {
                backgroundColor: 'secondary.dark',
                color: '#816347',
                '&:hover': {
                  backgroundColor: '#dcccaf',
                  color: '#816347'
                }
              },
              '& .MuiMenuItem-root:hover': {
                backgroundColor: '#dcccaf',
                color: '#816347'
              }
            }
          }
        }}
      >
        {
          setting.languageOption.map(option => {
            return <MenuItem sx={{ px: 1 }} value={option.languageCode} key={option.languageCode} disabled={option.languageCode === 'en'}>{option.display}</MenuItem>
          })
        }
      </Select>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: {
          sm: '30%',
          xs: '60%'
        },
        alignItems: 'flex-end',
        '& img': {
          width: '100%'
        }
      }}>
        <img src={logo_b} alt='戰國竹簡全文資料庫' />
        <Typography variant='caption' color='secondary.main'
          sx={{
            fontSize: {
              xs: '.6rem'
            }
          }}
        ></Typography>
      </Box>
    </Box >
  )
}