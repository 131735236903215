import { useNavigate, useLocation } from 'react-router-dom';
import './styles.scss';
import { Box, Link, Typography } from '@mui/material';
import { CustomAvatar } from 'component/CustomAvatar';
import navibarBg from 'assets/navbarBg.png';
import navibarIcon from 'assets/navibarIcon.png';
import { useTranslation } from 'react-i18next';
import { setting } from 'config/setting';


const preventDefault = (event) => event.preventDefault();
const menu = [
  {
    name: 'intro',
    path: '/intro'
  },
  {
    name: 'howTo',
    path: '/howto'
  },
  // {
  //   name: '閱讀文本',
  //   path: '/'
  // },
  {
    name: 'contact',
    email: setting.email
  }
]


export const Navigator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation('common');


  return (
    <Box
      onClick={preventDefault}
      sx={{
        display: 'flex',
        justifyContent: 'end',
        mb: 10,
        mr: {
          sm: -3,
          xs: -2
        }
      }}
    >
      <Box sx={{
        backgroundImage: `url(${navibarBg})`,
        backgroundRepeat: 'repeat-x',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: {
          xs: 'center'
        },
        pr: 1
      }}>
        <CustomAvatar src={navibarIcon} />
        {
          menu.map((menu, index, arr) => (
            <>
              <Link key={menu} underline='hover'
                sx={{
                  p: {
                    sm: 1
                  },
                  cursor: 'pointer',
                  fontSize: '.8rem',
                  color: location.pathname === menu.path ? 'whitesmoke' : 'secondary.light',
                  textShadow: location.pathname === menu.path ? '0px 0px 5px white' : null,
                  whiteSpace: 'nowrap'
                }}
                onClick={() => {
                  if (menu.path) {
                    navigate(menu.path);
                  } else if (menu.email) {
                    console.log(menu.email)
                    window.location.href = `mailto:${menu.email}`
                  }
                }}
              >
                {t(menu.name)}
              </Link>
              {(index < arr.length - 1) && <Typography variant='body2' sx={{ p: 1, color: 'secondary.light' }}>/</Typography>}
            </>
          ))
        }
      </Box>
    </Box>
  )
}