import { useState, createContext, useContext } from 'react';

const resultsContext = createContext([]);

export const ResultsService = ({ children }) => {
  const [results, setResults] = useState([]);
  const [readMoreResult, setReadMoreResult] = useState({});
  return <resultsContext.Provider value={{ results, setResults, readMoreResult, setReadMoreResult }}>
    {children}
  </resultsContext.Provider>
}

export const useResultsService = () => useContext(resultsContext);